<template>
  <v-card class="my-2">
    <v-toolbar dark class="blue" flat>
      <v-toolbar-title>
        <v-icon color="white"> mdi-facebook </v-icon>
        Einen Facebook-Lead manuell nachtragen
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>Sollten Leads</p>
      <v-form v-model="valid" class="d-block" id="form" ref="form">
        <v-text-field
          class="flex-grow-1 mx-2"
          v-model="leadId"
          :disabled="progress"
          label="Lead-ID"
          :rules="leadIdRules"
          required
        ></v-text-field>

        <v-checkbox
          v-model="sendMail"
          :disabled="progress"
          true-value="1"
          false-value="0"
          label="E-Mails versenden"
        ></v-checkbox>

        <v-btn
          :disabled="!valid"
          :loading="progress"
          color="success"
          class="mr-4"
          @click="validate"
        >
          Lead übertragen
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";

export default {
  name: "AddFacebookLeadManually",
  components: {},
  data() {
    return {
      progress: false, // should be true when fetching data
      campaignId: this.$route.params.id,
      lazy: true,
      valid: true,
      leadId: "",
      leadIdRules: [(v) => !!v || "leadId ist ein Pflichtfeld"],
      sendMail: false,
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.sendRequest();
      }
    },

    async sendRequest() {
      this.progress = true;

      var formData = new FormData();
      formData.append("provider", "facebook");
      formData.append("leadId", this.leadId);
      formData.append("sendMail", this.sendMail);

      let response = await this.postRequest(
        `campaigns/${this.campaignId}/leads`,
        null,
        formData
      );

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Lead wurde erfolgreich übertragen!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
        this.$refs.form.reset();
        // emit update
        this.$emit("leadFetched", true);
      } else {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Lead konnte nicht übertragen werden!";
        this.$root.snackbarGlobal.snackbarColor = "error";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
      this.progress = false;
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
