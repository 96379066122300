<template>
  <v-card v-if="!fetchingData" elevation="0" class="mt-2">
    <v-card-title class="headline"> Ziel Bedingung</v-card-title>
    <v-card-text>
      <p>
        Wenn der Lead folgendes TAG erhalten hat, wird der Funnel erfolgreich
        beendet ...
      </p>
      <v-form ref="form" class="mt-6" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-select
              v-if="!fetchingData"
              v-model="goalTag_id"
              :items="tags"
              :rules="[(v) => !!v || 'Item is required']"
              label="Ziel-Tag"
              item-text="name"
              item-value="id"
              outlined
              required
              hide-details
              chips
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn :disabled="!valid" color="success" @click="validate">
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CampaignFunnelBranchGoalConditions",
  components: {},

  props: ["funnel_id", "companies_id"],

  data() {
    return {
      data: [],
      fetchingData: true,
      previewTag: "",
      componentKey: 0,
      tags: [],
      goalTag_id: null,
      valueChanged: false,
      valid: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async conditionPatched(item) {
      // find index of item in this.conditions
      const index = this.conditions.findIndex(
        (condition) => condition.id === item.id
      );
      // replace item in this.conditions with item
      this.conditions[index] = item;
      this.componentKey++;
    },

    async getTags() {
      // GET leadTags?filter[companies_id]=1
      const response = await this.getRequest(`leadTags?filter[type]=status`);
      const response2 = await this.getRequest(
        `leadTags?filter[companies_id]=${this.companies_id}`
      );
      const response3 = await this.getRequest(`leadTags?filter[type]=system`);

      const tagsStatus = response.data.data;
      const tagsCompany = response2.data.data;
      const tagsSystem = response3.data.data;
      const tags = tagsStatus.concat(tagsCompany, tagsSystem);

      return tags;
    },

    async getFunnelData() {
      const response = await this.getRequest(
        `campaignFunnel/${this.funnel_id}`
      );
      return response.data.data;
    },

    async getData() {
      this.fetchingData = true;
      this.data = await this.getFunnelData();
      this.goalTag_id = this.data.goalTag_id;
      this.tags = await this.getTags();
      this.fetchingData = false;
    },

    async validate() {
      if (this.$refs.form.validate()) {
        // Formular ist gültig, führen Sie die gewünschte Aktion aus

        const params = new URLSearchParams();
        params.append("goalTag_id", this.goalTag_id);

        // PATCH campaignFunnel/{funnelId}/branches/{branchId}/sets/{id}
        this.patchRoute = `campaignFunnel/${this.funnel_id}`;
        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params);
        this.preloader = false;

        if (response.status === 200) {
          this.valueChanged = false;
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geupdated!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
