<template>
  <div>
    <v-btn depressed color="success" class="mb-2" @click="addBranch()">
      <v-icon left> mdi-plus </v-icon>
      Zweig hinzufügen
    </v-btn>

    <v-expansion-panels>
      <CampaignFunnelBranch
        v-for="(item, i) in branches"
        :key="i"
        :item="item"
        :companies_id="companies_id"
        @deleteItem="deleteItem"
      />
    </v-expansion-panels>
    <CampaignFunnelBranchGoalConditions
      :companies_id="companies_id"
      :funnel_id="funnel_id"
    />
    <CampaignFunnelSenderName
      :companies_id="companies_id"
      :funnel_id="funnel_id"
    />

    <Patcher
      :itemValue="data[0].isActive"
      :previewValue="data[0].isActive"
      itemName="isActive"
      itemLabel="Der Funnel ist aktiv"
      :hideLabel="true"
      :route="`campaignFunnel/${this.funnel_id}`"
      inputElement="input"
      inputType="checkbox"
      @emit="data[0].isActive = $event.value"
    ></Patcher>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import CampaignFunnelBranch from "@/components/campaign_management/campaign_funnel/CampaignFunnelBranch.vue";
import CampaignFunnelBranchGoalConditions from "@/components/campaign_management/campaign_funnel/CampaignFunnelBranchGoalConditions.vue";
import CampaignFunnelSenderName from "@/components/campaign_management/campaign_funnel/CampaignFunnelSenderName.vue";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "CampaignFunnel",
  components: {
    CampaignFunnelBranch,
    CampaignFunnelBranchGoalConditions,
    CampaignFunnelSenderName,
    Patcher,
  },
  props: ["data", "companies_id"],

  data() {
    return {
      fetchingData: false,
      funnel_id: this.data[0].id,
      branches: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // /campaignFunnel?filter[campaigns_id]=[id]
      this.fetchingData = true;
      const response = await this.getRequest(
        `campaignFunnel/${this.funnel_id}/branches`
      );

      this.fetchingData = false;

      if (response.status === 200) {
        this.branches = response.data.data;
      }
    },

    async addBranch() {
      // create form data
      const formData = new FormData();
      formData.append("name", "Neuer Zweig");

      const response = await this.postRequest(
        `campaignFunnel/${this.funnel_id}/branches`,
        null,
        formData
      );

      if (response.status === 201 || response.status === 200) {
        this.branches.push(response.data.data);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Ein neuer Zweig wurde hinzugefügt";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async deleteItem(item) {
      const response = await this.deleteRequest(
        `campaignFunnel/${item.funnel_id}/branches/${item.id}`
      );

      if (response.status === 200) {
        // remove item.id from this.branches
        this.branches = this.branches.filter((branch) => branch.id !== item.id);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Zweig wurde erfolgreich gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>

<style scoped>
.v-expansion-panel {
  background-color: #dddddd !important;
  color: #999999 !important;
  border: 3px solid #dddddd !important;
}

.v-expansion-panel.v-item--active {
  background-color: white !important;
  color: black !important;
  border: 3px solid green !important;
}
</style>
