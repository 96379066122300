<template>
  <v-card v-if="!fetchingData">
    <v-app-bar dark dense elevation="0" color="blue">
      <v-toolbar-title>Vorschau</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        elevation="0"
        :href="
          this.$frontendUrl +
          '?uuid=' +
          this.data.uuidPretty +
          '&type=page&branch=live'
        "
        target="_blank"
        x-small
        color="blue"
        class="mx-1 white--text"
        fab
        ><v-icon dark>mdi-eye</v-icon></v-btn
      >
    </v-app-bar>
    <v-card-text>
      <h3>{{ data.title }}</h3>
      <strong>Layout:</strong> {{ layoutTemplateName }}
      <span v-if="data.seoDescription"><br />{{ data.seoDescription }}</span>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
  <div v-else>Loading</div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";

export default {
  name: "LinkLandingpageTemplate",

  components: {},

  props: ["pageId"],

  data() {
    return {
      data: [],
      fetchingData: false,
      layoutTemplateName: "",
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // alle vorhandenen tempaltes
      this.fetchingData = true;
      let response = await this.getRequest(`pages/${this.pageId}`);
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
        this.getLayoutName(this.data.templates_id);
      }
    },

    async getLayoutName(templates_id) {
      // alle vorhandenen tempaltes
      let response = await this.getRequest(`pages/templates`);
      if (response.status === 200) {
        const result = response.data.data.find(
          (element) => element.id === templates_id
        );
        this.layoutTemplateName = result.title;
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
