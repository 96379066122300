<template>
  <v-card v-if="!fetchingData" elevation="0" class="mt-2">
    <v-card-title class="headline"> Absender Name</v-card-title>
    <v-card-text>
      <p>
        Z. B. "Max von Studio Mustermann". Wird als Absendernamen verwendet.
        Wenn nichts gespeichert ist, wird der Name des Studios verwendet.
      </p>
      <v-form ref="form" class="mt-6" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!fetchingData"
              v-model="fromName"
              label="Absender Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn :disabled="!valid" color="success" @click="validate">
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CampaignFunnelSenderName",
  components: {},

  props: ["funnel_id", "companies_id"],

  data() {
    return {
      data: [],
      fetchingData: true,
      previewTag: "",
      componentKey: 0,
      tags: [],
      fromName: "",
      valueChanged: false,
      valid: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getFunnelData() {
      const response = await this.getRequest(
        `campaignFunnel/${this.funnel_id}`
      );
      return response.data.data;
    },

    async getData() {
      this.fetchingData = true;
      this.data = await this.getFunnelData();
      this.fromName = this.data.fromName;
      this.fetchingData = false;
    },

    async validate() {
      if (this.$refs.form.validate()) {
        // Formular ist gültig, führen Sie die gewünschte Aktion aus
        const params = new URLSearchParams();
        params.append("fromName", this.fromName);

        // PATCH campaignFunnel/{funnelId}/branches/{branchId}/sets/{id}
        this.patchRoute = `campaignFunnel/${this.funnel_id}`;
        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params);
        this.preloader = false;

        if (response.status === 200) {
          this.valueChanged = false;
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geupdated!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
