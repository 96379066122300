<template>
  <div>
    <PatcherInputLight
      v-if="item.isMain !== '1'"
      :itemValue="branchName"
      itemName="name"
      itemLabel="Zweig-Name"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="text"
      @emit="
        branchName = $event.value;
        $emit('branchName', $event.value);
      "
      class="my-2"
    ></PatcherInputLight>

    <v-btn depressed color="success" class="mb-2" @click="addItem()">
      <v-icon left> mdi-plus </v-icon>
      Aktion hinzufügen
    </v-btn>

    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, i) in sets"
        :key="i"
        :class="`expansion-panel-second-bg`"
      >
        <v-expansion-panel-header>
          <table class="expansion-header">
            <tbody>
              <tr>
                <td>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on">
                        <v-icon small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn small text @click="deleteItem(item)">
                            <v-icon small>mdi-trash-can</v-icon>
                            Löschen
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
                <td>
                  Aktion: {{ item.subject ? item.subject : i }}
                  <span
                    v-if="item.isActive !== '1'"
                    class="red white--text pa-2"
                    >(OFFLINE)</span
                  >

                  <v-chip
                    v-if="item.valueChanged"
                    small
                    color="red"
                    text-color="white"
                  >
                    Nicht gespeichert
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CampaignFunnelSetForm
            :item="item"
            @patched="
              item.subject = $event.subject;
              item.isActive = $event.isActive;
            "
            @changed="item.valueChanged = $event"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import CampaignFunnelSetForm from "@/components/campaign_management/campaign_funnel/CampaignFunnelSetForm.vue";
import PatcherInputLight from "@/components/ui/PatcherInputLight.vue";
export default {
  name: "CampaignFunnelBranchSet",
  components: {
    CampaignFunnelSetForm,
    PatcherInputLight,
  },
  props: ["item"],
  data() {
    return {
      branchName: this.item.name,
      patchRoute: `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}`,
      sets: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // /campaignFunnel?filter[campaigns_id]=[id]
      this.fetchingData = true;
      const response = await this.getRequest(
        `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}/sets`
      );

      if (response.status === 200) {
        // add valueChanged = false to each item in the response
        response.data.data.forEach((item) => {
          item.valueChanged = false;
        });
        this.sets = response.data.data;
      }
      this.fetchingData = false;
    },

    validate() {
      if (this.$refs.form.validate()) {
        // Formular ist gültig, führen Sie die gewünschte Aktion aus
      }
    },

    async addItem() {
      // POST campaignFunnel/{funnelId}/branches/{branchId}/sets
      // create form data
      const formData = new FormData();
      //formData.append("name", "Neuer Zweig");

      const response = await this.postRequest(
        `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}/sets`,
        null,
        formData
      );

      if (response.status === 201 || response.status === 200) {
        this.sets.push(response.data.data);

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Eine neue Aktion wurde hinzugefügt";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async deleteItem(item) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Aktion wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        const response = await this.deleteRequest(
          `campaignFunnel/${item.funnel_id}/branches/${item.branch_id}/sets/${item.id}`
        );

        if (response.status === 200) {
          // remove item.id from this.branches
          this.sets = this.sets.filter((set) => set.id !== item.id);

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Aktion wurde erfolgreich gelöscht";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>

<style scoped>
.v-expansion-panel {
  background-color: #dddddd !important;
  color: #999999 !important;
  border: 3px solid #dddddd !important;
}

.v-expansion-panel.v-item--active {
  background-color: white !important;
  color: black !important;
  border: 3px solid green !important;
}
</style>
