<template>
  <div>
    <v-card v-if="!fetchingData">
      <v-app-bar dark dense elevation="0" color="blue">
        <v-toolbar-title>Landingpage</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          :href="
            this.$frontendUrl +
            '?uuid=' +
            this.data.uuid +
            '&type=page&branch=dev'
          "
          elevation="0"
          target="_blank"
          x-small
          color="white"
          class="mx-1 blue--text"
          fab
        >
          <v-icon dark>mdi-eye</v-icon>
        </v-btn>
        <!-- <v-btn
        elevation="0"
        :href="
          this.$frontendUrl +
            '?uuid=' +
            this.data.uuidPretty +
            '&type=page&branch=live'
        "
        target="_blank"
        x-small
        color="blue"
        class="mx-1 white--text"
        fab
        ><v-icon dark>mdi-eye</v-icon></v-btn
      > -->
      </v-app-bar>
      <v-card-text>
        <h3>{{ data.title }}</h3>
        <strong>Layout:</strong> {{ layoutTemplateName }} <br />
        <strong> ID: </strong>
        {{ pageId }}
        <span v-if="data.seoDescription"><br />{{ data.seoDescription }}</span>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="pageDialog = true">
          <v-icon left> mdi-pencil </v-icon>
          Landingpage bearbeiten
        </v-btn>

        <!--  <v-btn
          color="red"
          class="white--text"
          @click="unlinkLandingpage"
          disabled
        >
          <v-icon left>
            mdi-link-off
          </v-icon>
          Verknüpfung aufheben
        </v-btn> -->
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="pageDialog"
      max-width="1600px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="rerenderKey"
    >
      <v-card>
        <v-toolbar dark color="primary darken-3" elevation="0">
          <v-toolbar-title>Kampagnen-Landingpage</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="pageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <AddedLandingpageDetail :pageId="pageId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import AddedLandingpageDetail from "@/components/campaign_management/AddedLandingpageDetail.vue";

export default {
  name: "LandingpagePreview",

  components: {
    AddedLandingpageDetail,
  },

  props: ["pageId", "loading"],

  data() {
    return {
      data: [],
      fetchingData: false,
      layoutTemplateName: "",
      pageDialog: false,
      rerenderKey: 0,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // alle vorhandenen tempaltes
      this.fetchingData = true;
      let response = await this.getRequest(`pages/${this.pageId}`);
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
        this.getLayoutName(this.data.templates_id);
      }
    },

    async getLayoutName(templates_id) {
      // alle vorhandenen tempaltes
      let response = await this.getRequest(`pages/templates`);
      if (response.status === 200) {
        const result = response.data.data.find(
          (element) => element.id === templates_id
        );
        this.layoutTemplateName = result.title;
      }
    },

    editLandingpage() {
      this.$router.push({
        name: "tech.pages.:id",
        params: { id: this.pageId },
      });
    },

    unlinkLandingpage() {
      this.$emit("unlinkLandingpage", this.pageId);
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
