<template>
  <div>
    <h3 class="divider-hl primary darken-2 my-4">Checkliste</h3>
    <ul
      id="campaign-checklist"
      class="my-4"
      style="list-style-type: none; margin-left: 0; padding-left: 0"
    >
      <li v-for="(value, name) in checklist" :key="name">
        <v-icon color="green" v-if="value" small class="mr-2"
          >mdi-check-circle</v-icon
        >
        <v-icon color="red" v-else small class="mr-2">mdi-close-circle</v-icon>
        <span :class="value ? 'green--text' : 'red--text'">{{
          $t("campaignChecklist." + name)
        }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "CampaignChecklist",

  components: {},

  props: ["checklist"],

  data() {
    return {
      result: [],
    };
  },
};
</script>
