<template>
  <div>
    <PageHeader
      :pageTitle="pageTitle + '' + data.title"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mx-auto" :loading="fetchingData" flat>
          <v-toolbar dark class="primary" flat>
            <v-toolbar-title>Kampagne</v-toolbar-title>
            <v-spacer />
            <v-btn
              v-if="
                data.uuid &&
                data.isPageless !== '1' &&
                data.pages_id !== '' &&
                data.pages_id != null &&
                data.status !== 'complete'
              "
              :href="this.$frontendUrl + '?uuid=' + this.data.uuid"
              target="_blank"
              x-small
              color="blue"
              class="mx-1 white--text"
              :disabled="data.isApproved === '1' ? false : true"
              fab
              ><v-icon dark>mdi-eye</v-icon></v-btn
            >

            <!-- Link to admin -->
            <v-btn
              :href="this.$adminUrl + 'campaigns/' + this.data.id"
              target="_blank"
              x-small
              color="green darken-3"
              class="mx-1 white--text"
              :disabled="data.isApproved === '1' ? false : true"
              fab
              ><v-icon large dark>mdi-alpha-a</v-icon></v-btn
            >
          </v-toolbar>

          <v-card-text v-if="!fetchingData">
            <v-alert
              v-if="data.status === 'paused'"
              dense
              border="left"
              type="warning"
              >Die Kampagne ist pausiert</v-alert
            >

            <v-row no-gutters>
              <v-col cols="12" class="d-flex pr-2">
                <v-chip
                  v-if="data.status === 'open'"
                  color="red"
                  text-color="white"
                  class="white--text ma-1"
                >
                  <v-icon small left> mdi-close </v-icon>
                  Nicht freigegeben
                </v-chip>

                <v-chip
                  v-else-if="data.status === 'waiting'"
                  color="orange"
                  text-color="white"
                  class="white--text ma-1"
                >
                  <v-icon small left> mdi-clock-time-ten </v-icon>

                  Wartet auf Start
                </v-chip>

                <v-chip
                  v-else-if="data.status === 'active'"
                  color="green"
                  text-color="white"
                  class="white--text ma-1"
                >
                  <v-icon small left> mdi-rocket </v-icon>
                  Kampagne läuft
                </v-chip>

                <v-chip
                  v-else-if="data.status === 'complete'"
                  large
                  color="green"
                  class="white--text ma-1"
                >
                  <v-icon large left>
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  Abgeschlossen
                </v-chip>
              </v-col>
            </v-row>

            <template v-if="data.status !== 'complete'">
              <h3 class="divider-hl primary darken-2 my-4">
                Kampagnen-Links
                {{ data.isApproved !== "1" ? " (Noch nicht aufrufbar)" : "" }}
              </h3>
              <v-banner two-line>
                <v-avatar slot="icon" color="blue" size="40">
                  <v-icon icon="mdi-lock" color="white"> mdi-link </v-icon>
                </v-avatar>

                {{ this.$frontendUrl }}?uuid={{ data.uuid }}
              </v-banner>

              <v-banner two-line>
                <v-avatar slot="icon" color="blue" size="40">
                  <v-icon icon="mdi-lock" color="white"> mdi-facebook </v-icon>
                </v-avatar>

                {{ this.$frontendUrl }}?uuid={{ data.uuid }}&adsrc=facebook
              </v-banner>

              <v-banner two-line>
                <v-avatar slot="icon" color="blue" size="40">
                  <v-icon icon="mdi-lock" color="white"> mdi-google </v-icon>
                </v-avatar>

                {{ this.$frontendUrl }}?uuid={{ data.uuid }}&adsrc=google
              </v-banner>

              <v-banner
                v-if="data.isApproved !== '1'"
                class="red--text font-weight-bold"
              >
                Da die Kampagne noch nicht freigegeben wurde, sind die Kampagnen
                noch gesperrt! Für eine Vorschau nutze bitte die
                Vorschau-Funktion der verknüpften Landingpage.
              </v-banner>
            </template>

            <template v-if="data.isApproved === '1'">
              <!-- KAMPAGNE IST LIVE -->

              <h3 class="divider-hl primary darken-2 my-4">Kampagnen-Infos</h3>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>UID</td>
                      <td>{{ data.uuid }}</td>
                    </tr>

                    <tr>
                      <td>ID</td>
                      <td>{{ data.id }}</td>
                    </tr>

                    <tr>
                      <td>Titel</td>
                      <td>{{ data.title }}</td>
                    </tr>

                    <tr>
                      <td>Arbeitstitel</td>
                      <td>{{ data.name }}</td>
                    </tr>

                    <tr>
                      <td>Kategorisierung</td>
                      <td v-if="data.hasMitfitPay">PAY-Kampagne</td>
                      <td v-else-if="data.hasMitfitMember">MEMBER-Kampagne</td>
                      <td v-else-if="data.hasMitfitShop">SHOP-Kampagne</td>
                      <td v-else-if="data.facebookLeadAds">
                        Facebook Lead-ADs Kampagne
                      </td>
                    </tr>

                    <tr>
                      <td>Quelle</td>
                      <td>
                        <v-chip
                          v-if="data.planMonths_id"
                          color="teal"
                          class="white--text"
                        >
                          JMP
                        </v-chip>
                        <v-chip v-else color="blue-grey" class="white--text">
                          Direktkauf
                        </v-chip>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        {{ data.hasMitfitMember === "1" ? "Member" : "Leads" }}
                      </td>
                      <td>
                        <QuickCampaignStatistics
                          :id="data.id"
                          :isMember="data.hasMitfitMember"
                          :live="data.status === 'running' ? true : false"
                          :key="statisticKey"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>Kunde</td>
                      <td>
                        <a
                          :href="`/csm/company-manager/${data.companies_id}/overview`"
                          >{{ companyName }} (#{{ data.companies_id }})</a
                        >
                      </td>
                    </tr>

                    <tr v-if="data.facebookAccountId">
                      <td>Facebook Werbekonto-ID</td>
                      <td>
                        {{ fb_account_id }}

                        <v-btn @click="copy" x-small elevation="2" class="ml-2">
                          Copy
                          <v-icon right dark>
                            mdi-clipboard-check-outline
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>

                    <tr v-else>
                      <td>Facebook Werbekonto-ID</td>
                      <td>nicht vorhanden</td>
                    </tr>

                    <tr v-if="data.isPageless === '1'">
                      <td>Landingpage</td>
                      <td>Keine</td>
                    </tr>
                    <tr v-else>
                      <td>Landingpage</td>
                      <td>
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          depressed
                          color="primary"
                          @click="openPageInLiveMode()"
                        >
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                      </td>
                    </tr>

                    <tr v-if="data.hasMitfitMember === '1'">
                      <td>Typ</td>
                      <td>
                        <v-chip color="purple" class="white--text">
                          <v-icon left> mdi-alpha-m-circle </v-icon>
                          mitfitMEMBER
                        </v-chip>
                      </td>
                    </tr>
                    <tr v-else-if="data.facebookLeadAds === '1'">
                      <td>Typ</td>
                      <td>
                        <v-chip color="blue" class="white--text">
                          <v-icon left> mdi-facebook </v-icon>
                          Facebook Lead-ADs
                        </v-chip>
                      </td>
                    </tr>
                    <tr v-else-if="data.hasMitfitShop === '1'">
                      <td>Typ</td>
                      <td>
                        <v-chip color="cyan" class="white--text">
                          <v-icon left> mdi-alpha-s-circle </v-icon>
                          mitfitSHOP
                        </v-chip>
                      </td>
                    </tr>
                    <tr v-else>
                      <td>Typ</td>
                      <td>
                        <v-chip color="pink" class="white--text">
                          <v-icon left> mdi-alpha-c-circle </v-icon>
                          Campaign
                        </v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Laufzeit</td>
                      <td>
                        {{ formatDate(data.dateStart) }}
                        {{
                          data.isEndless === "1"
                            ? " - offen"
                            : " – " + formatDate(data.dateEnd)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Budget</td>
                      <td>{{ data.budget }} EUR</td>
                    </tr>

                    <tr>
                      <td>Werbequelle</td>
                      <td>{{ data.adSource }}</td>
                    </tr>

                    <tr>
                      <td>Werbequelle Bezeichnung</td>
                      <td>{{ data.adSourceCustom }}</td>
                    </tr>

                    <tr>
                      <td>E-Mail</td>
                      <td>
                        <strong>Betreff der E-Mail:</strong>
                        {{ data.thanksEmailSubject }}<br />
                        <strong>Inhalt der E-Mail:</strong> {{ data.thanksEmail
                        }}<br />
                        <strong>Text auf der Seite:</strong>
                        {{ data.thanksPage }}
                      </td>
                    </tr>

                    <tr>
                      <td>Weiterempfehlung</td>
                      <td>
                        {{
                          data.recommendationEnabled === "1" ? "AKTIV" : "AUS"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td>Mail-Channels</td>
                      <td>
                        <span v-for="id in data.mailChannels_ids" :key="id">
                          ID: {{ id }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>Feedback-Request</td>
                      <td>
                        {{
                          data.feedbackEnabled === "1"
                            ? "JA, NACH " +
                              data.feedbackRequestAfterDays +
                              " TAGEN"
                            : "AUS"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td>Feedback-Set</td>
                      <td v-if="data.feedbackSets_id">
                        {{ data.feedbackSets_id }}
                      </td>
                      <td v-else>KEINS</td>
                    </tr>

                    <tr v-if="data.feedbackSets_id">
                      <td>Feedback-Mail</td>
                      <td>
                        <strong>Betreff der E-Mail:</strong>
                        {{ data.feedbackRequestSubject }}<br />
                        <strong>Inhalt der E-Mail:</strong>
                        {{ data.feedbackRequestText }}<br />
                        <span v-if="data.feedbackRequestAfterDays"
                          >Der Request wird nach
                          {{ data.feedbackRequestAfterDays }} Tagen
                          gesendet.</span
                        >
                      </td>
                    </tr>

                    <tr>
                      <td>Scouting-Bonus</td>
                      <td>
                        <table v-if="data.scoutingBonusId" class="my-4">
                          <tr>
                            <td class="font-weight-bold">Bonus</td>
                            <td>{{ data.scoutingBonusId }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Laufzeit</td>
                            <td>
                              <div v-if="data.scoutingBonusStartDate">
                                {{ formatDate(data.scoutingBonusStartDate) }} -
                                <span v-if="data.scoutingBonusEndDate">
                                  {{ formatDate(data.scoutingBonusEndDate) }}
                                </span>
                                <span v-else> offen </span>
                              </div>
                              <div v-else>-</div>
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Direkt-Bonus</td>
                            <td>
                              {{ data.scoutingIsDirectBonus ? "JA" : "NEIN" }}
                            </td>
                          </tr>
                        </table>

                        <div v-else>AUS</div>
                      </td>
                    </tr>

                    <tr>
                      <td>Follow-Up-Kampagne</td>
                      <td v-if="data.followUpCampaign_id">
                        ID: {{ data.followUpCampaign_id }}
                      </td>
                      <td v-else>KEINE</td>
                    </tr>

                    <tr>
                      <td>Freigabe</td>
                      <td>
                        <strong>Qualitäts-Check:</strong>
                        {{ data.isChecked }}<br />
                        <strong>AD-Provider Setup-Check:</strong>
                        {{ data.adProviderSetup }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-banner two-line>
                <v-avatar slot="icon" color="red" size="40">
                  <v-icon icon="mdi-lock" color="white">
                    mdi-information-outline
                  </v-icon>
                </v-avatar>

                Diese Werte können bei aktiven - oder abgeschlossenen Kampagnen
                nicht verändert werden.
              </v-banner>
            </template>

            <Patcher
              :itemValue="data.isTest"
              itemName="isTest"
              itemLabel="Testkampagne"
              :hideLabel="true"
              :route="patchRoute"
              inputElement="switch"
              inputType=""
              :previewValue="data.isTest"
              hint="Das ist eine Testkampagne"
              @emit="data.isTest = $event.value"
            ></Patcher>

            <template v-if="data.isApproved !== '1'">
              <!-- BEARBEITUNG NUR WENN NICHT LIVE -->

              <Patcher
                :itemValue="data.uuid"
                itemName="uuid"
                itemLabel="ID"
                :hideLabel="false"
                :isLocked="true"
                inputElement=""
                inputType=""
                :disabled="true"
              ></Patcher>

              <v-row
                no-gutters
                class="py-1 align-center"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.05)"
              >
                <v-col cols="3" class="font-weight-bold pa-2 black--text">
                  Firma
                </v-col>
                <v-col cols="7" class="py-2 px-3">
                  <a
                    :href="`/csm/company-manager/${data.companies_id}/overview`"
                  >
                    {{ companyName }}
                  </a>
                </v-col>

                <v-col cols="3" class="font-weight-bold pa-2 black--text">
                  Quelle
                </v-col>
                <v-col cols="7" class="py-2 px-3">
                  <v-chip
                    v-if="data.planMonths_id"
                    color="teal"
                    class="white--text"
                  >
                    JMP
                  </v-chip>
                  <v-chip v-else color="blue-grey" class="white--text">
                    Direktkauf
                  </v-chip>
                </v-col>
              </v-row>

              <Patcher
                :itemValue="data.title"
                itemName="title"
                itemLabel="Titel"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :previewValue="data.title"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.title = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.name"
                itemName="name"
                itemLabel="Arbeitstitel"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="data.name"
                @emit="data.name = $event.value"
              ></Patcher>

              <h3 class="divider-hl primary darken-2 my-4">Landingpage</h3>

              <Patcher
                :itemValue="data.isPageless"
                itemName="isPageless"
                itemLabel="Keine Landingpage"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="data.isPageless"
                hint="Die Kampagne nutzt keine interne Landingpage"
                @emit="data.isPageless = $event.value"
              ></Patcher>

              <template v-if="!data.isPageless || data.isPageless === '0'">
                <h3 class="divider-hl primary darken-2 my-4">
                  Zuweisung eines Landingpage-Templates
                </h3>
                <p>
                  Hier kannst du der Kampagne ein Landingpage-Template zuweisen.
                  Nach der Zuweisung wird eine Kopie des Templates angelegt,
                  welches du an dieser Stelle indiviuell bearbeiten kannst.
                </p>
                <LinkLandingpageTemplate
                  :pageId="data.pages_id"
                  @pageLinked="getData()"
                />
              </template>

              <h3 class="divider-hl primary darken-2 my-4">
                Kategorisierungen
              </h3>

              <Patcher
                :itemValue="data.hasMitfitMember"
                itemName="hasMitfitMember"
                itemLabel="MEMBER-Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="data.hasMitfitMember"
                hint="Die Kampagne ist eine mitfitMEMBER-Kampagne"
                @emit="data.hasMitfitMember = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.hasMitfitShop"
                itemName="hasMitfitShop"
                itemLabel="SHOP-Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="data.hasMitfitShop"
                hint="Die Kampagne ist eine SHOP-Kampagne"
                @emit="data.hasMitfitShop = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.facebookLeadAds"
                itemName="facebookLeadAds"
                itemLabel="Facebook Lead-ADs Kampagne"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="data.facebookLeadAds"
                hint="Die Kampagne verwendet Facebook Lead-Ads"
                @emit="data.facebookLeadAds = $event.value"
              ></Patcher>

              <template v-if="data.facebookLeadAds === '1'">
                <h3 class="divider-hl primary darken-2 my-4">
                  Facebook Lead-Ad Einstellungen
                </h3>
                <Patcher
                  :itemValue="data.facebookCampaignId"
                  itemName="facebookCampaignId"
                  itemLabel="Facebook Kampagnen-ID"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  :disabled="data.isApproved !== '1' ? false : true"
                  :previewValue="data.facebookCampaignId"
                  @emit="data.facebookCampaignId = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.facebookFormId"
                  itemName="facebookFormId"
                  itemLabel="Facebook Formular-ID"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  :previewValue="data.facebookFormId"
                  :disabled="data.isApproved !== '1' ? false : true"
                  @emit="data.facebookFormId = $event.value"
                ></Patcher>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">Laufzeit</h3>

              <Patcher
                :itemValue="data.dateStart"
                itemName="dateStart"
                itemLabel="Startdatum"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="datepicker"
                inputType=""
                :disabled="data.isApproved !== '1' ? false : true"
                :previewValue="formatDate(data.dateStart)"
                @emit="data.dateStart = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.isEndless"
                itemName="isEndless"
                itemLabel="Endlose Laufzeit"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :previewValue="data.isEndless"
                :disabled="data.isApproved !== '1' ? false : true"
                hint="Die Kampagne läuft endlos"
                @emit="data.isEndless = $event.value"
              ></Patcher>

              <template v-if="data.isEndless !== '1'">
                <Patcher
                  :itemValue="data.dateEnd"
                  itemName="dateEnd"
                  itemLabel="Enddatum"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="datepicker"
                  inputType=""
                  :previewValue="formatDate(data.dateEnd)"
                  :disabled="data.isApproved !== '1' ? false : true"
                  @emit="data.dateEnd = $event.value"
                ></Patcher>
              </template>

              <h3 class="my-4 divider-hl primary darken-2">Budget</h3>

              <Patcher
                :itemValue="data.budget"
                itemName="budget"
                itemLabel="Budget"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="number"
                :previewValue="data.budget"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.budget = $event.value"
              ></Patcher>

              <h3 class="my-4 divider-hl primary darken-2">Quellen</h3>

              <Patcher
                :itemValue="data.adSource"
                itemName="adSource"
                itemLabel="Werbequelle"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="select"
                inputType=""
                :previewValue="data.adSource"
                :selectValueKey="value"
                :selectTextKey="name"
                :selectOptions="adSources"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.adSource = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.adSourceCustom"
                itemName="adSourceCustom"
                itemLabel="Werbequelle Bezeichnung"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :previewValue="data.adSourceCustom"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.adSourceCustom = $event.value"
              ></Patcher>

              <h3 class="my-4 divider-hl primary darken-2">Tracking</h3>

              <Patcher
                :itemValue="data.facebookPixelId"
                itemName="facebookPixelId"
                itemLabel="Facebook-Pixel ID"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :previewValue="data.facebookPixelId"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.facebookPixelId = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.googleTagId"
                itemName="googleTagId"
                itemLabel="Google-Tag ID"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :previewValue="data.googleTagId"
                :disabled="data.isApproved !== '1' ? false : true"
                @emit="data.googleTagId = $event.value"
              ></Patcher>

              <h3 class="divider-hl primary darken-2 my-4">
                Vielen Dank für die Teilnahme
              </h3>

              <p class="red--text font-weight-bold">
                Beachte: Die Texte gelten NICHT für mitfitMEMBER und mitfitSHOP
                Kampagnen.
              </p>

              <Patcher
                :previewValue="data.thanksEmailSubject"
                itemName="thanksEmailSubject"
                itemLabel="Betreff der E-Mail"
                :hideLabel="false"
                :itemValue="data.thanksEmailSubject"
                :route="patchRoute"
                inputElement="input"
                inputType="text"
                :selectOptions="null"
                selectValueKey=""
                selectTextKey=""
                @emit="data.thanksEmailSubject = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.thanksEmail"
                itemName="thanksEmail"
                itemLabel="Inhalt der E-Mail"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="textarea-md"
                inputType="text"
                @emit="data.thanksEmail = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.thanksPage"
                itemName="thanksPage"
                itemLabel="Text auf der Seite"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="textarea-md"
                inputType="text"
                @emit="data.thanksPage = $event.value"
              ></Patcher>

              <v-sheet
                color="white"
                elevation="1"
                class="pa-4 text-caption caption"
              >
                <strong>Platzhalter Bonus-Kalender:</strong><br />
                Bonus-Name: %bonuscalendar-item-title%<br />
                Zusätzlicher Text zum Bonus: %bonuscalendar-additional-text%
              </v-sheet>

              <h3 class="divider-hl primary darken-2 my-4">Weiterempfehlung</h3>

              <Patcher
                :itemValue="data.recommendationEnabled"
                itemName="recommendationEnabled"
                itemLabel="Weiterempfehlung aktiviert"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :previewValue="data.recommendationEnabled"
                :disabled="data.isApproved !== '1' ? false : true"
                hint="Dem Teilnehmer wird die Möglichkeit gegeben, die Kampagne weiterzuempfehlen"
                @emit="data.recommendationEnabled = $event.value"
              ></Patcher>

              <template v-if="data.recommendationEnabled === '1'">
                <!-- <Patcher
                  :itemValue="data.recommendationType"
                  itemName="recommendationType"
                  itemLabel="Plattform"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="select"
                  inputType=""
                  :previewValue="data.recommendationType"
                  :selectValueKey="value"
                  :selectTextKey="name"
                  :selectOptions="recommendationTypes"
                  :disabled="!data.recommendationEnabled"
                  @emit="data.recommendationType = $event.value"
                ></Patcher> -->

                <!-- <Patcher
                  v-if="data.recommendationType !== 'whatsapp'"
                  :previewValue="data.recommendationTitle"
                  itemName="recommendationTitle"
                  itemLabel="Titel"
                  :hideLabel="false"
                  :itemValue="data.recommendationTitle"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  :selectOptions="null"
                  selectValueKey=""
                  selectTextKey=""
                  @emit="data.recommendationTitle = $event.value"
                ></Patcher> -->

                <Patcher
                  :itemValue="data.recommendationText"
                  itemName="recommendationText"
                  itemLabel="Inhalt"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  @emit="data.recommendationText = $event.value"
                ></Patcher>

                <Patcher
                  :previewValue="data.customRecommendationUrl"
                  itemName="customRecommendationUrl"
                  itemLabel="Eigene URL, die empfohlen werden soll"
                  :hideLabel="false"
                  :itemValue="data.customRecommendationUrl"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="url"
                  :selectOptions="null"
                  selectValueKey=""
                  selectTextKey=""
                  @emit="data.customRecommendationUrl = $event.value"
                ></Patcher>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">Mail-Channels</h3>

              <p>
                Jeder Lead wird in die ausgewählten Mail-Channels eingetragen.
                <strong>ACHTUNG:</strong> Der Kunde benötigt das Addon
                <RouterLink
                  :to="`/csm/company-manager/${data.companies_id}/addons`"
                  target="_blank"
                  >Info-Mails</RouterLink
                >.
              </p>
              <Patcher
                v-if="!fetchingMailChannels"
                :itemValue="data.mailChannels_ids"
                itemName="mailChannels_ids"
                itemLabel="Mail-Channels"
                :route="patchRoute"
                inputElement="multiselect"
                inputType=""
                selectValueKey="id"
                selectTextKey="name"
                :selectOptions="allMailChannels"
                :previewValue="
                  buildArrayWithNames(data.mailChannels_ids, 'mailChannels')
                "
                @emit="data.mailChannels_ids = $event.value"
              ></Patcher>

              <h3 class="divider-hl primary darken-2 my-4">Externe APIs</h3>

              <p>
                Wähle die APIs aus, die für diese Kampagne verwendet werden
                sollen.
              </p>

              <template v-if="data.companies_id">
                <Patcher
                  v-if="!fetchingExternalApis"
                  :itemValue="data.externalApi_ids"
                  itemName="externalApi_ids"
                  itemLabel="Externe Anbieter"
                  :route="patchRoute"
                  inputElement="multiselect"
                  inputType=""
                  selectValueKey="providers_id"
                  selectTextKey="name"
                  :selectOptions="allExternalApis"
                  :previewValue="
                    buildArrayWithNames(data.externalApi_ids, 'externalApis')
                  "
                  @emit="data.externalApi_ids = $event.value"
                ></Patcher>
              </template>
              <template v-else>
                <p>
                  Bitte wähle zuerst eine Firma aus, um die externen APIs
                  auswählen zu können.
                </p>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">Events</h3>

              <p>
                Wähle das Event aus, das mit dieser Kampagne verknüpft werden
                soll.
              </p>

              <template v-if="data.companies_id">
                <Patcher
                  v-if="!fetchingCompanyEvents"
                  :itemValue="data.events_id"
                  itemName="events_id"
                  itemLabel="Event"
                  :route="patchRoute"
                  inputElement="select"
                  inputType=""
                  selectValueKey="id"
                  selectTextKey="title"
                  :selectOptions="allCompanyEvents"
                  :previewValue="getItemName(data.events_id, 'company_events')"
                  @emit="data.events_id = $event.value"
                ></Patcher>
              </template>
              <template v-else>
                <p>
                  Bitte wähle zuerst eine Firma aus, um ein Event auswählen zu
                  können.
                </p>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">Feedback-Request</h3>

              <Patcher
                :itemValue="data.feedbackEnabled"
                itemName="feedbackEnabled"
                itemLabel="Feedback nach X Tagen anfragen"
                :hideLabel="true"
                :route="patchRoute"
                inputElement="switch"
                inputType=""
                :previewValue="data.feedbackEnabled"
                :disabled="data.isApproved !== '1' ? false : true"
                hint="Dem Teilnehmer wird nach X Tagen die Möglichkeit gegeben, ein Feedback zu geben."
                @emit="data.feedbackEnabled = $event.value"
              ></Patcher>

              <template v-if="data.feedbackEnabled === '1'">
                <!-- SELECT A FEEDBACK-SET -->
                <Patcher
                  :itemValue="data.feedbackSets_id"
                  itemName="feedbackSets_id"
                  itemLabel="Feedback-Set"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="feedbackpicker"
                  inputType=""
                  previewValue=""
                  selectOptions=""
                  @emit="data.feedbackSets_id = $event.value"
                ></Patcher>

                <Patcher
                  :previewValue="data.feedbackRequestSubject"
                  itemName="feedbackRequestSubject"
                  itemLabel="Betreff der E-Mail"
                  :hideLabel="false"
                  :itemValue="data.feedbackRequestSubject"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="text"
                  :selectOptions="null"
                  selectValueKey=""
                  selectTextKey=""
                  @emit="data.feedbackRequestSubject = $event.value"
                ></Patcher>

                <Patcher
                  :itemValue="data.feedbackRequestText"
                  itemName="feedbackRequestText"
                  itemLabel="Inhalt der E-Mail"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="textarea-md"
                  inputType="text"
                  hint="<strong>Platzhalter:</strong><br />
                    <strong>%firstName%</strong> - Vorname des Teilnehmers<br />
                    <strong>%feedbackUrl%</strong> - URL zur Feedback-Seite<br />
                    <strong>%studioName%</strong> - Name des Studios<br />
                    <strong>%recommendationUrl%</strong> - URL zur
                    Weiterempfehlung"
                  @emit="data.feedbackRequestText = $event.value"
                ></Patcher>

                <Patcher
                  :previewValue="data.feedbackRequestAfterDays"
                  itemName="feedbackRequestAfterDays"
                  itemLabel="Nach wievielen Tagen soll das Feedback angefragt werden?"
                  :hideLabel="false"
                  :itemValue="data.feedbackRequestAfterDays"
                  :route="patchRoute"
                  inputElement="input"
                  inputType="number"
                  :selectOptions="null"
                  selectValueKey=""
                  selectTextKey=""
                  @emit="data.feedbackRequestAfterDays = $event.value"
                ></Patcher>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">Scouting-Bonus</h3>
              <p>
                Gibt es einen Bonus für Leute, die die Kampagne weiterempfehlen?
                Dann kannst Du diesen hier auswählen.
              </p>
              <Patcher
                :itemValue="data.scoutingBonusId"
                itemName="scoutingBonusId"
                itemLabel="Scouting Bonus"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="bonuspicker"
                inputType=""
                previewValue=""
                selectOptions=""
                @emit="data.scoutingBonusId = $event.value"
              ></Patcher>

              <template v-if="data.scoutingBonusId">
                <Patcher
                  :itemValue="data.scoutingBonusStartDate"
                  itemName="scoutingBonusStartDate"
                  itemLabel="Startdatum"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="datepicker"
                  inputType=""
                  :disabled="data.isApproved !== '1' ? false : true"
                  :previewValue="formatDate(data.scoutingBonusStartDate)"
                  @emit="data.scoutingBonusStartDate = $event.value"
                ></Patcher>
                <Patcher
                  :itemValue="data.scoutingBonusEndDate"
                  itemName="scoutingBonusEndDate"
                  itemLabel="Enddatum"
                  :hideLabel="false"
                  :route="patchRoute"
                  inputElement="datepicker"
                  inputType=""
                  :disabled="data.isApproved !== '1' ? false : true"
                  :previewValue="formatDate(data.scoutingBonusEndDate)"
                  @emit="data.scoutingBonusEndDate = $event.value"
                ></Patcher>

                <!-- DIRECT-BONUS -->

                <Patcher
                  :itemValue="data.scoutingIsDirectBonus"
                  itemName="scoutingIsDirectBonus"
                  itemLabel="Direkt-Bonus"
                  :hideLabel="true"
                  :route="patchRoute"
                  inputElement="switch"
                  inputType=""
                  :disabled="data.isApproved !== '1' ? false : true"
                  :previewValue="data.hasMitfitMember"
                  hint="Der Bonus ist ein Direkt-Bonus, der bei Erfolg direkt ausgespielt wird."
                  @emit="data.scoutingIsDirectBonus = $event.value"
                ></Patcher>

                <template v-if="data.scoutingIsDirectBonus">
                  <Patcher
                    :previewValue="data.scoutingDirectBonusEmailSubject"
                    itemName="scoutingDirectBonusEmailSubject"
                    itemLabel="Betreff der E-Mail"
                    :hideLabel="false"
                    :itemValue="data.scoutingDirectBonusEmailSubject"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    :selectOptions="null"
                    selectValueKey=""
                    selectTextKey=""
                    @emit="data.scoutingDirectBonusEmailSubject = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.scoutingDirectBonusEmailBody"
                    itemName="scoutingDirectBonusEmailBody"
                    itemLabel="Inhalt der Bonus-E-Mail"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="textarea-md"
                    inputType="text"
                    @emit="data.scoutingDirectBonusEmailBody = $event.value"
                  ></Patcher>
                </template>
              </template>

              <h3 class="divider-hl primary darken-2 my-4">
                Follow-Up-Kampagne
              </h3>
              <p>
                Hier kann die Kampagne ausgewählt werden, die nach Abschluss der
                Kampagne automatisch gestartet werden soll. Die Weiterleitung
                auf diese Kampagne ersetzt die Ausgabe der "Vielen Dank"-Seite.
              </p>

              <Patcher
                :itemValue="data.followUpCampaign_id"
                itemName="followUpCampaign_id"
                itemLabel="Follow-Up-Kampagne"
                :hideLabel="false"
                :route="patchRoute"
                inputElement="campaignpicker"
                @emit="data.followUpCampaign_id = $event.value"
              ></Patcher>

              <h3 class="divider-hl primary darken-2 my-4">Qualitäts-Checks</h3>
              <p>
                Bitte überprüfe die Kampagne, die dazugehörige Landingpage und
                die Einrichtung bei Facebook (Meta) auf Richtigkeit und
                fehlerfreie Ausführung.
              </p>

              <Patcher
                :itemValue="data.isChecked"
                itemName="isChecked"
                itemLabel="Kampagne wurde geprüft"
                :hideLabel="true"
                :route="
                  data.isChecked === '0'
                    ? patchRouteChecked
                    : patchRouteUnsetChecked
                "
                inputElement="switch"
                inputType=""
                :previewValue="data.isChecked"
                :disabled="data.isApproved === '1' ? true : false"
                hint="Die Kampagne und Landingpage wurden auf Funktion und Darstellung geprüft und abgenommen."
                @emit="data.isChecked = $event.value"
              ></Patcher>

              <Patcher
                :itemValue="data.adProviderSetup"
                itemName="adProviderSetup"
                itemLabel="Kampagne wurde auf Meta geprüft"
                :hideLabel="true"
                :route="
                  data.adProviderSetup === '0'
                    ? patchRoutePrioviderSetup
                    : patchRouteUnsetPrioviderSetup
                "
                inputElement="switch"
                inputType=""
                :previewValue="data.adProviderSetup"
                :disabled="data.isApproved === '1' ? true : false"
                hint="Die Kampagne wurde bei Meta eingerichtet und auf Richtigkeit überprüft."
                @emit="data.adProviderSetup = $event.value"
              ></Patcher>
            </template>
          </v-card-text>
        </v-card>

        <CampaignActions
          v-if="data.id"
          :data="data"
          :key="StateKey"
          @stateChanged="getData()"
        ></CampaignActions>

        <AddFacebookLeadManually
          v-if="data.facebookLeadAds === '1'"
          @leadFetched="
            notesKey++;
            statisticKey++;
          "
        >
        </AddFacebookLeadManually>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <OrderSummary
              v-if="!fetchingData"
              :orderId="data.orders_id"
              :data="data"
              :ordersProducts_id="data.ordersProducts_id"
            ></OrderSummary>
          </v-col>

          <v-col cols="12">
            <!-- global component -->
            <Notes
              v-if="!fetchingData"
              :route="`/orders/${data.orders_id}/products/${data.ordersProducts_id}/notes`"
              :key="notesKey"
            />
          </v-col>

          <v-col cols="12">
            <!-- global component -->
            <QrCodeLinksMembers
              v-if="
                (!fetchingData && data.hasMitfitMember === '1') ||
                data.hasMitfitShop === '1'
              "
              :campaignUuid="data.uuid"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <CampaignFunnelApp
          :campaignId="this.$route.params.id"
          :companies_id="data.companies_id"
        />
      </v-col>

      <v-col cols="12" v-if="data.isApproved === '1'">
        <CampaignStatistics :data="data"></CampaignStatistics>
      </v-col>

      <v-col v-if="!fetchingData" cols="12">
        <CampaignEmbedCode :uuid="data.uuid"></CampaignEmbedCode>
      </v-col>
    </v-row>

    <v-dialog
      v-model="pageDialog"
      max-width="1600px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="rerenderKey"
    >
      <v-card>
        <v-toolbar dark color="red darken-3" elevation="0">
          <v-toolbar-title>(AKTIVE KAMPAGNE) Landingpage</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="pageDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <AddedLandingpageDetail :pageId="data.pages_id" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader.vue";
import Patcher from "@/components/ui/Patcher.vue";
import Notes from "@/components/global/notes/Notes.vue";
import OrderSummary from "@/components/orders/OrderSummary.vue";
import CampaignActions from "@/components/campaign_management/CampaignActions.vue";
import LinkLandingpageTemplate from "@/components/campaign_management/LinkLandingpageTemplate.vue";
import AddedLandingpageDetail from "@/components/campaign_management/AddedLandingpageDetail.vue";
import CampaignStatistics from "@/components/campaign_management/CampaignStatistics.vue";
import QrCodeLinksMembers from "@/components/campaign_management/QrCodeLinksMembers.vue";
import QuickCampaignStatistics from "@/components/campaign_management/QuickCampaignStatistics.vue";
import AddFacebookLeadManually from "@/components/campaign_management/AddFacebookLeadManually.vue";
import CampaignFunnelApp from "@/components/campaign_management/CampaignFunnelApp.vue";
import CampaignEmbedCode from "@/components/campaign_management/CampaignEmbedCode.vue";
export default {
  name: "Campaigndetail",

  components: {
    PageHeader,
    Notes,
    Patcher,
    OrderSummary,
    CampaignActions,
    LinkLandingpageTemplate,
    AddedLandingpageDetail,
    CampaignStatistics,
    QrCodeLinksMembers,
    QuickCampaignStatistics,
    AddFacebookLeadManually,
    CampaignFunnelApp,
    CampaignEmbedCode,
  },

  data() {
    return {
      data: [],
      companyName: "",
      pageDialog: false,
      notesKey: 0,
      statisticKey: 0,
      fetchingData: false,
      fetchingMailChannels: false,
      fetchingExternalApis: false,
      fetchingCompanyEvents: false,
      patchRoute: "campaigns/" + this.$route.params.id,
      patchRouteChecked: "campaigns/" + this.$route.params.id + "/setChecked",
      patchRouteUnsetChecked:
        "campaigns/" + this.$route.params.id + "/unsetChecked",
      patchRoutePrioviderSetup:
        "campaigns/" + this.$route.params.id + "/setAdProviderSetup",
      patchRouteUnsetPrioviderSetup:
        "campaigns/" + this.$route.params.id + "/unsetAdProviderSetup",

      pageTitle: "Kampagne: ",
      pageDescription: "",
      adSources: [
        {
          name: "Other",
          value: "Other",
        },
        {
          name: "Facebook Ads",
          value: "Facebook Ads",
        },
      ],
      recommendationTypes: [
        {
          name: "WhatsApp",
          value: "whatsapp",
        },
      ],
      value: null,
      name: null,
      StateKey: 0,
      campaignSetup: false,
      rerenderKey: 0,
      fb_account_id: null,
      allMailChannels: [],
      allExternalApis: [],
      allEvents: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async openPageInLiveMode() {
      const userChoice = await this.$dialog.confirm({
        text: `Du öffnest nun die Live-Seite der Kampagne. Änderungen an der Seite werden sofort sichtbar sein. Möchtest du fortfahren?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.pageDialog = true;
      }
    },
    async getCompanieName() {
      let response = await this.getRequest(
        "companies/" + this.data.companies_id
      ); // await data from mixin
      if (response.status === 200) {
        this.companyName = response.data.data[0].name;
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        "campaigns/" + this.$route.params.id
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.data = response.data.data;
        this.fb_account_id = response.data.data.facebookAccountId;

        // check if this.data contains mailChannels_ids. if yes convert it to an array
        if (this.data.mailChannels_ids) {
          const carray = this.data.mailChannels_ids.split(",");
          this.data.mailChannels_ids = carray;
        }

        // check if this.data contains externalApi_ids. if yes convert it to an array
        if (this.data.externalApi_ids) {
          const carray = this.data.externalApi_ids.split(",");
          this.data.externalApi_ids = carray;
        }

        this.getCompanieName();
        this.getAllMailChannels();
        if (this.data.companies_id) {
          this.getAllExternalApis();
          this.getAllCompanyEvents();
        }
      }
    },

    async copy() {
      try {
        await navigator.clipboard.writeText(this.fb_account_id);
        alert(
          `ID ${this.fb_account_id} wurde in der Zwischenablage gespeichert.`
        ); // success
      } catch ($e) {
        alert(
          "Ein Fehler ist aufgetreten. Dein Browser erlaubt diese Funktion nicht. Bitte kopiere die ID per Hand."
        ); // error
      }
    },

    async getAllMailChannels() {
      this.fetchingMailChannels = true;
      let response = await this.getRequest(`addons/infoMails/channels`);
      this.allMailChannels = response.data.data;
      this.fetchingMailChannels = false;
    },

    async getAllExternalApis() {
      // GET {{host}}/v1/companies/1/externalApis?filter[isCampaignApi]=1&filter[isValid]=1
      this.fetchingExternalApis = true;
      let response = await this.getRequest(
        `companies/${this.data.companies_id}/externalApis?filter[isCampaignApi]=1&filter[isValid]=1`
      );
      this.allExternalApis = response.data.data;
      this.fetchingExternalApis = false;
    },

    async getAllCompanyEvents() {
      // GET {{host}}/v1/companies/1/externalApis?filter[isCampaignApi]=1&filter[isValid]=1
      this.fetchingCompanyEvents = true;
      let response = await this.getRequest(
        `companies/${this.data.companies_id}/addons/eventcalendar/events`
      );
      this.allCompanyEvents = response.data.data;

      // add a blank event to the array
      this.allCompanyEvents.unshift({
        id: 0,
        title: "Kein Event",
      });
      this.fetchingCompanyEvents = false;
    },

    getItemName(id, type = "mailChannels") {
      // get the name of the item by id
      let name = "";

      if (!id || id === "0") {
        return "Kein Event";
      }

      if (type === "company_events") {
        this.allCompanyEvents.forEach((comp) => {
          if (comp.id === id) {
            name = comp.title;
          }
        });
      }
      return name;
    },

    buildArrayWithNames(items, type = "mailChannels") {
      if (!items) {
        return [];
      }

      // check if items is an array. if not convert it to an array
      if (!Array.isArray(items)) {
        items = items.split(",");
      }

      let nameArray = [];

      if (type === "mailChannels") {
        items.forEach((item) => {
          this.allMailChannels.forEach((comp) => {
            if (comp.id === item) {
              nameArray.push(comp.name);
            }
          });
        });
      } else if (type === "externalApis") {
        items.forEach((item) => {
          this.allExternalApis.forEach((comp) => {
            if (comp.providers_id === item) {
              nameArray.push(comp.name);
            }
          });
        });
      } else if (type === "company_events") {
        items.forEach((item) => {
          this.allCompanyEvents.forEach((comp) => {
            if (comp.id === item) {
              nameArray.push(comp.title);
            }
          });
        });
      }

      return nameArray;
    },
  },

  watch: {
    data: {
      handler() {
        this.StateKey += 1;
      },
      deep: true,
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
