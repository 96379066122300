<template>
  <v-card
    v-if="!fetchingData"
    elevation="2"
    class="expansion-panel-conditions-light-bg"
  >
    <v-card-title class="headline"> Zweig Bedingungen </v-card-title>
    <v-card-text>
      <v-btn depressed small color="success" class="mb-2" @click="addItem()">
        <v-icon left> mdi-plus </v-icon>
        Bedingung hinzufügen
      </v-btn>
      <v-expansion-panels :key="componentKey">
        <v-expansion-panel
          v-for="(item, i) in conditions"
          :key="i"
          class="expansion-panel-conditions-bg"
        >
          <v-expansion-panel-header>
            <table class="expansion-header">
              <tbody>
                <tr>
                  <td>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon v-bind="attrs" v-on="on">
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn small text @click="deleteItem(item)">
                              <v-icon small>mdi-trash-can</v-icon>
                              Löschen
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                  <td>Wenn Lead das TAG</td>
                  <td>
                    <v-chip small color="primary">
                      {{ preparePreviewTag(item) }}
                    </v-chip>
                  </td>
                  <td>
                    besitzt
                    <v-icon small> mdi-arrow-right-bold </v-icon>
                  </td>
                  <td>
                    <v-chip small color="primary">
                      {{ preparePreviewBranch(item) }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <CampaignFunnelBranchSwitchConditionsForm
              :item="item"
              :branches="branches"
              :tags="tags"
              @patched="conditionPatched($event)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import CampaignFunnelBranchSwitchConditionsForm from "@/components/campaign_management/campaign_funnel/CampaignFunnelBranchSwitchConditionsForm.vue";
export default {
  name: "CampaignFunnelBranchSwitchConditions",
  components: {
    CampaignFunnelBranchSwitchConditionsForm,
  },

  props: ["item", "companies_id"],

  data() {
    return {
      data: [],
      branches: [],
      conditions: [],
      fetchingData: true,
      previewTag: "",
      previewBranch: "",
      componentKey: 0,
      tags: [],
      valid: false,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async conditionPatched(item) {
      // find index of item in this.conditions
      const index = this.conditions.findIndex(
        (condition) => condition.id === item.id
      );
      // replace item in this.conditions with item
      this.conditions[index] = item;
      this.componentKey++;
    },

    preparePreviewTag(item) {
      // get previewTag Name by item.tag_id

      if (
        !item.ifLeadTag_id ||
        item.ifLeadTag_id === null ||
        item.ifLeadTag_id === 0 ||
        item.ifLeadTag_id === "0"
      ) {
        return "-";
      }
      const tag = this.tags.find((tag) => tag.id === item.ifLeadTag_id);

      if (!tag) {
        return "-";
      }

      return tag.name;
    },

    preparePreviewBranch(item) {
      // get previewBranch Name by item.branch_id
      if (
        !item.thenBranch_id ||
        item.thenBranch_id === null ||
        item.thenBranch_id === 0 ||
        item.thenBranch_id === "0"
      ) {
        return "-";
      }

      const branch = this.branches.find(
        (branch) => branch.id === item.thenBranch_id
      );

      if (!branch) {
        return "-";
      }
      return branch.name;
    },

    async getBranches() {
      // /campaignFunnel/{funnelId}/branches
      const response = await this.getRequest(
        `campaignFunnel/${this.item.funnel_id}/branches`
      );

      // remove item with id this.item.branch_id
      response.data.data = response.data.data.filter(
        (item) => item.id !== this.item.id
      );

      if (response.status === 200) {
        return response.data.data;
      } else {
        return [];
      }
    },

    async getTags() {
      // GET leadTags?filter[companies_id]=1
      const response = await this.getRequest(`leadTags?filter[type]=status`);
      const response2 = await this.getRequest(
        `leadTags?filter[companies_id]=${this.companies_id}`
      );
      const response3 = await this.getRequest(`leadTags?filter[type]=system`);

      const tagsStatus = response.data.data;
      const tagsCompany = response2.data.data;
      const tagsSystem = response3.data.data;
      const tags = tagsStatus.concat(tagsCompany, tagsSystem);

      return tags;
    },

    async getConditions() {
      // /campaignFunnel/{funnelId}/branches
      const response = await this.getRequest(
        `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}/conditions`
      );

      // remove item with id this.item.branch_id
      response.data.data = response.data.data.filter(
        (item) => item.id !== this.item.id
      );

      if (response.status === 200) {
        return response.data.data;
      } else {
        return [];
      }
    },

    async getData() {
      this.fetchingData = true;
      this.conditions = await this.getConditions();
      this.branches = await this.getBranches();
      this.tags = await this.getTags();
      this.fetchingData = false;
    },

    async addItem() {
      // create form data
      const formData = new FormData();
      //formData.append("name", "Neuer Zweig");

      const response = await this.postRequest(
        `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}/conditions`,
        null,
        formData
      );

      if (response.status === 201 || response.status === 200) {
        this.conditions.push(response.data.data);
        this.componentKey++;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Eine neue Bedingung wurde hinzugefügt";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async deleteItem(item) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Bedingung wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        const response = await this.deleteRequest(
          `campaignFunnel/${item.funnel_id}/branches/${item.branch_id}/conditions/${item.id}`
        );

        if (response.status === 200) {
          // remove item.id from this.branches
          this.conditions = this.conditions.filter(
            (condition) => condition.id !== item.id
          );

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Bedingung wurde erfolgreich gelöscht";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
