<template>
  <v-chip :disabled="fetchingData" :color="defineColor()" text-color="white">
    <v-avatar left :class="defineColorBubble()">
      <span class="caption">{{ result.leadsCount }}</span>
    </v-avatar>
    {{ isMember === "1" ? "Member" : "Leads" }}
  </v-chip>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "QuickCampaignStatistics",

  components: {},

  props: ["id", "live", "isMember"],

  data() {
    return {
      fetchingData: false,
      result: [],
      lookIntervall: null,
    };
  },

  created: function () {
    this.getData();

    if (this.live) {
      this.lookIntervall = setInterval(
        function () {
          this.getData();
        }.bind(this),
        60000
      );
    }
  },

  destroyed: function () {
    clearInterval(this.lookIntervall);
    this.lookIntervall = null;
  },

  methods: {
    defineColor() {
      // this.result.leadsCount to number
      const leadCount = parseInt(this.result.leadsCount);

      if (leadCount === 0) {
        return "red";
      } else if (leadCount < 5) {
        return "orange";
      } else {
        return "green";
      }
    },

    defineColorBubble() {
      // this.result.leadsCount to number
      const leadCount = parseInt(this.result.leadsCount);

      if (leadCount === 0) {
        return "red darken-4";
      } else if (leadCount < 5) {
        return "orange darken-2";
      } else {
        return "green darken-4";
      }
    },

    async getData() {
      this.fetchingData = true;
      const response = await this.getRequest(
        `/campaigns/${this.id}/statistics/leadsCount`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.result = response.data.data;
      }
    },
  },
  mixins: [apiRequest],
};
</script>
