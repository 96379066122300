<template>
  <div>
    <div v-if="!edit">
      <v-row no-gutters>
        <v-col cols="auto" @click="editItem()">
          <v-text-field
            v-model="currentValue"
            dense
            outlined
            :placeholder="itemLabel"
            :label="itemLabel"
            readonly
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-col cols="auto">
            <v-text-field
              v-model="currentValue"
              :type="inputType"
              :rules="inputType !== 'email' ? [rules.required] : [rules.email]"
              :placeholder="itemLabel"
              :label="itemLabel"
              outlined
              dense
              hide-details
              autofocus
            ></v-text-field>
          </v-col>

          <v-col>
            <v-btn
              fab
              x-small
              color="green"
              elevation="0"
              :disabled="!valid"
              @click="patchItem()"
              class="mx-1 white--text"
            >
              <v-icon small>mdi-content-save</v-icon>
            </v-btn>

            <v-btn
              fab
              x-small
              color="secondary"
              elevation="0"
              @click="abortEdit()"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "Patcher",

  components: {},

  props: [
    "itemValue",
    "itemName",
    "route",
    "inputElement",
    "inputType",
    "selectOptions",
    "itemId",
    "switchLabel",
    "selectValueKey",
    "selectTextKey",
    "previewValue",
    "label",
    "disabled",
    "itemLabel",
    "isLocked",
    "hideLabel",
    "hint",
    "multiple",
  ],

  created: async function () {
    if (this.itemValueUsed === "" || !this.itemValueUsed) {
      this.itemValueUsed = "value";
    }

    if (this.itemTextUsed === "" || !this.itemTextUsed) {
      this.itemTextUsed = "name";
    }

    /* get needed data (eg for selections) via api to ref Var */

    if (this.inputElement === "companypicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("companies"); // await data from mixin
      this.allCompanies = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "pagepicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("pages"); // await data from mixin
      this.allPages = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "campaignpicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest(
        "campaigns?filter[status]=waiting,paused,active"
      ); // await data from mixin
      this.allCampaigns = response.data.data;
      this.helpDataFetched = true;
    } else if (this.inputElement === "bonuspicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("scouting-boni"); // await data from mixin
      this.allBoni = response.data.data;
      this.allBoni.unshift({ id: 0, name: "Kein Bonus" });
      this.helpDataFetched = true;
    } else if (this.inputElement === "feedbackpicker") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("feedbackSets"); // await data from mixin
      this.allFeedbacks = response.data.data;
      this.allFeedbacks.unshift({
        id: 0,
        name: "Kein Feedback (nutze Standard)",
      });
      this.helpDataFetched = true;
    } else if (this.itemName === "type_id") {
      /* fetch data for selects and id->name connection */
      let response = await this.getRequest("products/types"); // await data from mixin
      this.productTypes = response.data.data;
      this.helpDataFetched = true;
    } else {
      this.helpDataFetched = true;
    }
  },

  data() {
    return {
      hover: false,
      componentKey: 0,
      randomValue: (Math.random() + 1).toString(36).substring(7),
      helpDataFetched: false,
      edit: false,
      valid: true,
      currentValue: this.itemValue,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        url: (value) => {
          const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return pattern.test(value) || "Invalid URL";
        },
      },
    };
  },

  methods: {
    editItem() {
      if (!this.disabled && !this.isLocked) {
        this.edit = true;
        this.componentKey += 1;
      }
    },

    abortEdit() {
      this.edit = false;
      this.currentValue = this.itemValue;
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append(this.itemName, this.currentValue);

      let addIdToRoute = "";
      if (this.itemId) {
        addIdToRoute = "/" + this.itemId;
      }

      this.preloader = true;
      let response = await this.patchRequest(
        `${this.route}${addIdToRoute}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$emit("emit", {
          action: "itemPatched",
          name: this.itemName,
          value: this.currentValue,
          response: response.data.data,
        });

        this.edit = false;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde geupdated!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    patchItem() {
      if (this.$refs.form.validate()) {
        this.safeData();
      }
    },
  },

  mixins: [apiRequest],
};
</script>
