<template>
  <div>
    <div v-if="!this.preloader">
      <Page
        v-if="this.data.branches"
        :id="this.pageId"
        :data="data"
        @reload="reloadData"
        @copiedbranches="reloadData"
        :key="componentKey"
      />
      <div v-else>
        <p>Keine Daten vorhanden</p>
      </div>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import Page from "@/components/pages/Page.vue";
export default {
  name: "AddedLandingpageDetail",

  components: {
    Page,
  },

  props: ["pageId"],

  data() {
    return {
      pageTitle: "Page:",
      pageDescription:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non officiis accusantium omnis ad? Porro ea perferendis veritatis repellat sit et nulla quae ab omnis, eos beatae facilis obcaecati aliquam harum!",
      data: [], // the main reactive data array
      preloader: false,
      componentKey: 0,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    reloadData() {
      this.getData();
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("pages/" + this.pageId);
      this.preloader = false;

      if (response.status === 200) {
        this.data = response.data.data;
        this.componentKey += 1;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
