<template>
  <v-card class="mt-2" flat>
    <v-toolbar dark class="grey darken-3" flat>
      <v-toolbar-title>Statistiken</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <h3>Weiterempfehlungen</h3>

      <v-data-table
        class="elevation-2 my-4"
        :loading="fetchingData"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="result.visitorSessions"
      >
        <template v-slot:[`item.scout`]="{ item }">
          <span v-if="item.scoutUuid">
            <v-icon small color="success">mdi-checkbox-blank-circle</v-icon>
            {{ item.scoutFullName }}
          </span>
          <span v-else-if="item.rscoutUuid">
            <v-icon small color="purple">mdi-checkbox-blank-circle</v-icon>
            {{ item.rscoutFullName }}
          </span>
          <span v-else>
            <v-icon small color="grey lighten-2"
              >mdi-checkbox-blank-circle</v-icon
            >
            -
          </span>
        </template>

        <template v-slot:[`item.scoutFullName`]="{ item }">
          <span v-if="item.scoutFullName">
            {{ item.scoutFullName }}
          </span>

          <span v-else> - </span>
        </template>

        <template v-slot:[`item.scoutingSession`]="{ item }">
          <span v-if="item.scoutingSession">
            <span v-if="item.scoutingSession[0].src">{{
              item.scoutingSession[0].src
            }}</span>
            <span v-else>-</span>
          </span>
          <span v-else-if="item.rscoutSrc">
            {{ item.rscoutSrc }}
          </span>
          <span v-else> - </span>
        </template>

        <template v-slot:[`item.lead`]="{ item }">
          {{ item.leadFirstName }} {{ item.leadLastName }}
        </template>

        <template v-slot:[`item.created`]="{ item }">
          {{ formatDateTime(item.created) }}
        </template>

        <template v-slot:[`item.scoutertyp`]="{ item }">
          <span v-if="item.scoutUuid">
            <v-icon small color="blue">mdi-checkbox-blank-circle</v-icon>
          </span>
          <span v-else-if="item.rscoutUuid">
            <v-icon small color="purple">mdi-checkbox-blank-circle</v-icon>
          </span>
          <span v-else>
            <v-icon v-if="item.scoutingSession" small color="orange lighten-2">
              mdi-checkbox-blank-circle
            </v-icon>
            <v-icon v-else small color="grey lighten-2">
              mdi-checkbox-blank-circle
            </v-icon>
          </span>
        </template>

        <template v-slot:[`item.success`]="{ item }">
          <span v-if="item.generatedLeadUuid">
            <v-icon small color="green">mdi-thumb-up</v-icon>
          </span>
          <span v-else>
            <v-icon small color="grey">mdi-clock-outline</v-icon>
          </span>
        </template>
      </v-data-table>

      <p>
        <v-icon small color="blue">mdi-checkbox-blank-circle</v-icon> =
        Teilnehmer aus Kampagne
        <br />
        <v-icon small color="purple">mdi-checkbox-blank-circle</v-icon> =
        Registrierter Scout
        <br />
        <v-icon small color="orange lighten-2"
          >mdi-checkbox-blank-circle</v-icon
        >
        = Anonymer Scout
        <br />
        <v-icon small color="grey lighten-2">mdi-checkbox-blank-circle</v-icon>
        = Kein Scout
      </p>

      <p>
        <v-icon small color="green">mdi-thumb-up</v-icon> = Neuer Lead generiert
        <br />
        <v-icon small color="grey">mdi-clock-outline</v-icon> = offen
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CampaignStatistics",

  components: {},

  props: ["data"],

  data() {
    return {
      fetchingData: false,
      result: [],
      headers: [
        { text: "", value: "success" },
        { text: "Datum", value: "created" },
        { text: "Scout", value: "scoutFullName" },
        { text: "", value: "scoutertyp", sortable: false },
        /* { text: "Scout-Quelle", value: "rscoutSrc" }, */
        { text: "Scout-Quelle", value: "scoutingSession" },
        /* { text: "Typ", value: "type" }, */
        { text: "Besucher-Quelle", value: "src" },
        { text: "Besucher-IP", value: "ip4" },
        { text: "Lead", value: "generatedLeadData.fullName" },
      ],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      const response = await this.getRequest(
        `/campaigns/${this.data.id}/statistics`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.result = response.data.data;
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
