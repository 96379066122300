<template>
  <v-card class="mt-2" flat>
    <v-toolbar dark class="grey darken-3" flat>
      <v-toolbar-title>
        <v-icon color="white"> mdi-qrcode </v-icon>
        QR-Code-URLs / Empfehlungs-URLs
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <h3 class="mt-2">RollUp</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-qrcode </v-icon>
        </v-avatar>

        {{ this.$scoutingUrl }}?cmp={{
          campaignUuid
        }}&src=rollup&type=publiclink
      </v-banner>

      <h3 class="mt-2">T-Shirt</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-qrcode </v-icon>
        </v-avatar>

        {{ this.$scoutingUrl }}?cmp={{ campaignUuid }}&src=shirt&type=publiclink
      </v-banner>

      <h3 class="mt-2">Sticker</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-qrcode </v-icon>
        </v-avatar>

        {{ this.$scoutingUrl }}?cmp={{
          campaignUuid
        }}&src=sticker&type=publiclink
      </v-banner>

      <h3 class="mt-2">Visitenkarte</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-qrcode </v-icon>
        </v-avatar>

        {{ this.$frontendUrl }}?uuid={{
          campaignUuid
        }}&s=1&src=vcard&type=publiclink
      </v-banner>

      <h3 class="mt-2">Theke</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-qrcode </v-icon>
        </v-avatar>

        {{ this.$frontendUrl }}?uuid={{
          campaignUuid
        }}&s=1&src=entry&type=publiclink
      </v-banner>

      <h3 class="mt-2">Webseite</h3>

      <v-banner two-line>
        <v-avatar slot="icon" color="blue" size="40">
          <v-icon icon="mdi-lock" color="white"> mdi-link </v-icon>
        </v-avatar>

        {{ this.$frontendUrl }}?uuid={{
          campaignUuid
        }}&s=1&src=website&type=publiclink
      </v-banner>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "QrCodeLinksMembers",
  props: ["campaignUuid"],
};
</script>
