<template>
  <div v-if="!fetchingData">
    <!-- <pre>{{ data }}</pre> -->
    <template v-if="!pageId">
      <v-combobox
        v-model="selectedLandingpageTemplate"
        :disabled="linkingProgess"
        :items="data"
        @change="componentKey += 1"
        label="Wähle ein Landingpage-Template"
        item-text="title"
        item-value="id"
        outlined
      ></v-combobox>

      <template v-if="selectedLandingpageTemplate">
        <LandingpageTemplatePreview
          :pageId="selectedLandingpageTemplate.id"
          :loading="linkingProgess"
          :key="componentKey"
        />
        <v-btn
          depressed
          large
          color="green"
          class="mt-4 white--text"
          :loading="linkingProgess"
          @click="duplicateAndAssignPage()"
        >
          <v-icon left dark> mdi-link </v-icon>
          Landingpage-Template zuweisen
        </v-btn>
      </template>
    </template>
    <template v-else>
      <LandingpagePreview
        :pageId="pageId"
        :key="componentKey"
        @unlinkLandingpage="unlinkLandingpage"
      />
    </template>
  </div>
  <div v-else>Loading</div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import LandingpageTemplatePreview from "@/components/campaign_management/LandingpageTemplatePreview.vue";
import LandingpagePreview from "@/components/campaign_management/LandingpagePreview.vue";

export default {
  name: "LinkLandingpageTemplate",

  components: {
    LandingpageTemplatePreview,
    LandingpagePreview,
  },

  props: ["pageId"],

  data() {
    return {
      data: [],
      componentKey: 0,
      fetchingData: false,
      linkingProgess: false,
      selectedLandingpageTemplate: null,
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // alle vorhandenen tempaltes
      this.fetchingData = true;
      let response = await this.getRequest("pages");
      this.fetchingData = false;
      if (response.status === 200) {
        this.data = response.data.data;
      }
    },

    async duplicateAndAssignPage() {
      // PATCH /campaigns/{id}/duplicateAndAssignPage/{pageId}
      this.linkingProgess = true;
      let response = await this.patchRequest(
        `campaigns/${this.$route.params.id}/duplicateAndAssignPage/${this.selectedLandingpageTemplate.id}`,
        null,
        null
      );
      this.linkingProgess = false;
      if (response.status === 200) {
        this.getData();
        // emit event to parent
        this.$emit("pageLinked");
      }
    },

    async unlinkLandingpage(pageId) {
      // PATCH /campaigns/{id}/duplicateAndAssignPage/{pageId}
      this.linkingProgess = true;
      let response = await this.patchRequest(
        `campaigns/${this.$route.params.id}/unlinkLandingpage/${pageId}`,
        null,
        null
      );
      this.linkingProgess = false;
      if (response.status === 200) {
        this.getData();
      }
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
