<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <table class="expansion-header">
        <tbody>
          <tr>
            <td>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        small
                        text
                        @click="deleteItem(item)"
                        :disabled="item.isMain === '1' ? true : false"
                      >
                        <v-icon small>mdi-trash-can</v-icon>
                        Löschen
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td><strong>ZWEIG</strong></td>
            <td>{{ item.name === "Main" ? "[Hauptzweig]" : item.name }}</td>
          </tr>
        </tbody>
      </table>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12">
          <CampaignFunnelBranchSet
            v-if="!fetchingData"
            :item="item"
            @branchName="item.name = $event"
          />
        </v-col>
        <v-col cols="12">
          <CampaignFunnelBranchSwitchConditions
            v-if="!fetchingData"
            :item="item"
            :companies_id="companies_id"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import CampaignFunnelBranchSet from "@/components/campaign_management/campaign_funnel/CampaignFunnelBranchSet.vue";
import CampaignFunnelBranchSwitchConditions from "@/components/campaign_management/campaign_funnel/CampaignFunnelBranchSwitchConditions.vue";

export default {
  name: "CampaignFunnel",
  components: {
    CampaignFunnelBranchSet,
    CampaignFunnelBranchSwitchConditions,
  },
  props: ["item", "companies_id"],

  data() {
    return {
      fetchingData: false,
      sets: [],
    };
  },

  methods: {
    async deleteItem(item) {
      const userChoice = await this.$dialog.confirm({
        text: `Soll dieser Zweig wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.$emit("deleteItem", item);
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
