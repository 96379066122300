<template>
  <v-card class="mt-2" flat>
    <v-toolbar dark class="grey darken-3" flat>
      <v-toolbar-title>Kampagne einbetten</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-textarea
        v-if="code"
        class="mt-2"
        v-model="unescapedCode"
        readonly
        dense
        auto-grow
        rounded
        filled
        background-color="grey lighten-3"
        style="font-size: 0.95em"
      ></v-textarea>
      <v-btn @click="copyToClipboard" class="mt-2" color="primary" small dark>
        In Zwischenablage kopieren
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
const VUE_APP_FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;
const VUE_APP_ENVIROMENT = process.env.VUE_APP_ENVIROMENT;

let suffix = "";

if (VUE_APP_ENVIROMENT === "devel") {
  suffix = "_dev";
} else if (VUE_APP_ENVIROMENT === "test") {
  suffix = "_test";
} else if (VUE_APP_ENVIROMENT === "preprod") {
  suffix = "_preprod";
}

export default {
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },

  created: function () {
    this.code = `&lt;div id=&quot;mitfitpage&quot; uuid=&quot;${this.uuid}&quot; adsrc=&quot;homepage&quot;&gt;&lt;/div&gt;\n&lt;script&gt;var d = document,g = d.createElement(&quot;script&quot;),s = d.getElementsByTagName(&quot;script&quot;)[0];g.type = &quot;text/javascript&quot;;g.async = true;g.defer = true;g.src = &quot;${VUE_APP_FRONTEND_URL}embed${suffix}.js&quot;;g.onload = function () {};s.parentNode.insertBefore(g, s);&lt;/script&gt;`;
    this.unescapedCode = this.unescapeHtml(this.code);
  },

  data() {
    return {
      code: null,
      unescapedCode: "",
    };
  },

  methods: {
    unescapeHtml(escapedStr) {
      const doc = new DOMParser().parseFromString(escapedStr, "text/html");
      return doc.documentElement.textContent;
    },
    copyToClipboard() {
      // copy tag to clipboard
      navigator.clipboard.writeText(this.unescapedCode);
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarText =
        "Der Code wurde in die Zwischenablage kopiert!";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 2500;
    },
  },
};
</script>

<style>
.code-holder {
  background-color: #e2e2e2 !important;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  width: 100%;
  white-space: pre-wrap !important;
}
</style>
