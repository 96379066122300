<template>
  <v-form
    v-if="isMounted"
    ref="form"
    class="mt-6"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          v-model="subject"
          :rules="reqRules"
          label="Betreff"
          placeholder="Eine tolle E-Mail"
          outlined
          required
          hide-details
          @input="valueChanged = true"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
          v-model="sendAfterCount"
          type="number"
          :rules="reqRules"
          label="Sende nach"
          placeholder="1"
          outlined
          required
          hide-details
          @input="valueChanged = true"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          v-model="sendPeriod"
          :items="['hours', 'days']"
          :rules="[(v) => !!v || 'Item is required']"
          label="Einheit"
          placeholder="hours"
          outlined
          required
          hide-details
          @change="valueChanged = true"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-md-editor
          v-model="message"
          height="400px"
          left-toolbar="h bold italic | ul ol | link | myCustomSelect"
          :toolbar="toolbar"
          mode="edit"
          @input="valueChanged = true"
        ></v-md-editor>

        <LeadPlaceholderSelector />
      </v-col>

      <v-col cols="12">
        <v-checkbox v-model="isActive" label="Aktiv?"></v-checkbox>
      </v-col>

      <v-col cols="12">
        <v-btn :disabled="!valid" color="success" @click="validate">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import LeadPlaceholderSelector from "@/components/ui/LeadPlaceholderSelector.vue";
export default {
  name: "CampaignFunnelBranchSet",
  components: {
    LeadPlaceholderSelector,
  },
  props: ["item"],
  data() {
    return {
      data: [],
      subject: this.item.subject,
      message: this.item.message,
      sendAfterCount: this.item.sendAfterCount,
      sendPeriod: this.item.sendPeriod,
      isActive: this.item.isActive === "1" ? true : false,
      valueChanged: false,
      valid: true,
      placeholders: ["lead-first-name", "lead-last-name", "lead-email"], // Ihr Array von Platzhaltern
      toolbar: {},
      isMounted: false,
      componentKey: 0,
      dataFetched: false,
      patchRoute: null,
      name: "",
      reqRules: [(v) => !!v || "Pflichtfeld"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,
      checkbox: false,
    };
  },

  created: function () {
    this.toolbar = {
      myCustomSelect: {
        title: "Basis-Platzhalter",
        icon: "mdi mdi-percent-outline", // Ein Icon von Material Design Icons
        text: "Basis-Platzhalter",
        menus: {
          mode: "panel",
          itemWidth: "auto",
          rowNum: 5,
          items: this.createMenuItems(), // Rufen Sie die Funktion auf, um die Menüelemente zu erstellen
        },
        onClick: (editor) => {
          console.log("click myCustomSelect" + editor);
        },
      },
    };
    this.isMounted = true;
  },

  // watch for changes in isChanged
  watch: {
    valueChanged: function () {
      console.log("isChanged", this.valueChanged);
      this.$emit("changed", this.valueChanged);
    },
  },

  methods: {
    createMenuItems() {
      return this.placeholders.map((placeholder) => ({
        text: placeholder,
        action(editor) {
          editor.insert(function (selected) {
            const prefix = "%";
            const suffix = "%";
            const content = selected || placeholder;
            return {
              text: `${prefix}${content}${suffix}`,
              selected: content,
            };
          });
        },
      }));
    },

    async validate() {
      if (this.$refs.form.validate()) {
        // Formular ist gültig, führen Sie die gewünschte Aktion aus

        const params = new URLSearchParams();
        params.append("subject", this.subject);
        params.append("message", this.message);
        params.append("sendAfterCount", this.sendAfterCount);
        params.append("sendPeriod", this.sendPeriod);
        params.append("isActive", this.isActive ? 1 : 0);

        // PATCH campaignFunnel/{funnelId}/branches/{branchId}/sets/{id}
        this.patchRoute = `campaignFunnel/${this.item.funnel_id}/branches/${this.item.id}/sets/${this.item.id}`;
        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params);
        this.preloader = false;

        if (response.status === 200) {
          this.valueChanged = false;
          this.$emit("changed", false);
          this.$emit("patched", response.data.data);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geupdated!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    async deleteItem(item) {
      console.log("Delete Item", item);
    },
  },
  mixins: [apiRequest, helpers],
};
</script>

<style>
.v-md-editor__toolbar-item-myCustomSelect .v-md-editor__menu-item {
  padding: 0.3rem 0.5rem !important;
  margin: 0.12rem !important;
  border-radius: 15px !important;
  background-color: #555 !important;
  color: white !important;
}
</style>
