<template>
  <v-card v-if="!fetchingData" class="mt-2" flat>
    <v-toolbar dark class="grey darken-3" flat>
      <v-toolbar-title>Steuerung</v-toolbar-title>
    </v-toolbar>

    <v-card-text v-if="!fetchingData">
      <template v-if="data.isApproved !== '1'">
        <v-btn
          class="white--text"
          :loading="updatingData"
          :disabled="!isReady ? true : false"
          color="green"
          x-large
          @click="approvePrompt()"
        >
          <v-icon left> mdi-rocket </v-icon>
          Kampagne starten
        </v-btn>

        <CampaignChecklist :checklist="checklist" />
      </template>

      <template v-else>
        <template v-if="data.status !== 'complete'">
          <v-btn
            class="white--text ma-1"
            :loading="updatingData"
            color="red"
            @click="endPrompt()"
          >
            <v-icon left> mdi-flag-checkered </v-icon>
            Kampagne beenden
          </v-btn>

          <v-btn
            class="white--text ma-1"
            :loading="updatingData"
            color="red"
            @click="disApprovePrompt()"
          >
            <v-icon left> mdi-cached </v-icon>
            Freigabe zurücknehmen
          </v-btn>

          <v-btn
            v-if="data.status === 'active'"
            class="white--text ma-1"
            :loading="updatingData"
            color="red"
            @click="pausePrompt()"
          >
            <v-icon left> mdi-pause </v-icon>
            Kampagne pausieren
          </v-btn>

          <v-btn
            v-else-if="data.status === 'paused'"
            class="white--text ma-1"
            :loading="updatingData"
            color="green"
            @click="resumePrompt()"
          >
            <v-icon left> mdi-pause </v-icon>
            Kampagne fortsetzen
          </v-btn>
        </template>

        <template v-else>
          <v-btn
            class="white--text ma-1"
            :loading="updatingData"
            color="green"
            @click="restartPrompt()"
          >
            <v-icon left> mdi-restart </v-icon>
            Kampagne neu starten
          </v-btn>
        </template>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import CampaignChecklist from "@/components/campaign_management/CampaignChecklist.vue";
export default {
  name: "CampaignActions",

  components: { CampaignChecklist },

  props: ["data"],

  data() {
    return {
      fetchingData: false,
      updatingData: false,
      isReady: false,
      result: [],
      checklist: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      const response = await this.getRequest(
        `/campaigns/${this.data.id}/isReady`
      );
      this.fetchingData = false;

      if (response.status === 200) {
        this.result = response.data.data;
        this.isReady = response.data.data.isReady;
        this.checklist = response.data.data.reason;
      }
    },

    async approvePrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll diese Kampagne tatsächlich freigegeben werden?",
        title: "Bist du dir sicher?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/approve`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Kampagne wurde freigegeben";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async disApprovePrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll die Freigabe der Kampagne tatsächlich zurückgenommen werden? Die Landingpage ist dann nicht mehr erreichbar.",
        title: "Freigabe zurücknehmen?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/disapprove`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);

          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Freigabe wurde zurückgenommen";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async pausePrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll die Kampagne tatsächlich pausiert werden? Die Landingpage ist dann nicht mehr erreichbar.",
        title: "Kampagne pausieren?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/pause`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Kampagne wurde pausiert";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async resumePrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll die Kampagne tatsächlich wieder aktiviert werden? Die Landingpage ist dann wieder erreichbar.",
        title: "Kampagne fortsetzen?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/resume`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Kampagne wurde aktiviert";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async restartPrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll die Kampagne tatsächlich neu gestartet werden?",
        title: "Kampagne neu starten?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/restart`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Kampagne wurde neu gestartet";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async endPrompt() {
      const userChoice = await this.$dialog.confirm({
        text: "Soll die Kampagne tatsächlich vorzeitig beendet werden?",
        title: "Kampagne beenden?",
        waitForResult: true,
      });

      if (userChoice) {
        this.updatingData = true;
        let response = await this.patchRequest(
          `campaigns/${this.$route.params.id}/stop`
        );
        this.updatingData = false;

        if (response.status === 200) {
          this.$emit("stateChanged", null);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Die Kampagne wurde vorzeitig beendet.";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },
  mixins: [apiRequest],
};
</script>
