<template>
  <v-card class="mt-2" flat>
    <v-toolbar dark class="grey darken-3" flat>
      <v-toolbar-title>Funnel</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-if="!fetchingData">
      <CampaignFunnel
        v-if="result.length > 0"
        :data="result"
        :companies_id="companies_id"
      />

      <v-btn
        v-else
        depressed
        color="success"
        class="mb-2"
        :loading="creatingItem"
        @click="createFunnel()"
      >
        <v-icon left> mdi-plus </v-icon>
        Funnel anlegen
      </v-btn>
    </v-card-text>
    <v-card-text v-else>
      <v-skeleton-loader type="table" :loading="fetchingData" />
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import CampaignFunnel from "@/components/campaign_management/campaign_funnel/CampaignFunnel.vue";
export default {
  name: "CampaignFunnelApp",
  components: {
    CampaignFunnel,
  },
  props: ["campaignId", "companies_id"],

  data() {
    return {
      fetchingData: false,
      creatingItem: false,
      result: [],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      // /campaignFunnel?filter[campaigns_id]=[id]
      this.fetchingData = true;
      const response = await this.getRequest(
        `campaignFunnel?filter[campaigns_id]=${this.campaignId}`
      );

      if (response.status === 200) {
        this.result = response.data.data;
      }
      this.fetchingData = false;
    },

    async createFunnel() {
      // create form data
      const formData = new FormData();
      formData.append("campaigns_id", this.campaignId);

      this.creatingItem = true;
      const response = await this.postRequest("campaignFunnel", null, formData);

      if (response.status === 201 || response.status === 200) {
        // add the new item to the result
        this.result = [...this.result, response.data.data];
      }
      this.creatingItem = false;
    },
  },
  mixins: [apiRequest, helpers],
};
</script>

<style>
table.expansion-header {
  display: block;
  width: auto !important;
}
table.expansion-header td {
  padding: 0 0.15rem;
}

.expansion-panel-second-bg {
  background-color: #f8f8f8 !important;
}

.expansion-panel-conditions-bg {
  background-color: #effdef !important;
}

.expansion-panel-conditions-light-bg {
  background-color: #f3fdf3 !important;
}

.expansion-panel-second-bg .v-input__control,
.expansion-panel-conditions-bg .v-input__control {
  background-color: rgba(255, 255, 255, 0.35) !important;
}

.v-input--checkbox .v-input__control {
  background-color: transparent !important;
}
</style>
