<template>
  <v-form ref="form" class="mt-6" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="10">
            <v-select
              v-model="ifLeadTag_id"
              :items="tags"
              :rules="[(v) => !!v || 'Item is required']"
              label="Lead-Tag"
              item-text="name"
              item-value="id"
              outlined
              required
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="2" class="align-self-center">
            <v-icon large> mdi-arrow-right-bold </v-icon>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="!goalReached" cols="12" md="6">
        <v-select
          v-model="thenBranch_id"
          :items="branches"
          item-text="name"
          item-value="id"
          :rules="[(v) => !!v || 'Pflichtfeld']"
          label="Zweig"
          placeholder="Zweig-Name"
          outlined
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-btn :disabled="!valid" color="success" @click="validate">
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "CampaignFunnelBranchSwitchConditionsForm",
  props: ["item", "branches", "tags"],
  data() {
    return {
      data: [],
      goalReached: this.item.goalReached === "1" ? true : false,
      ifLeadTag_id: this.item.ifLeadTag_id ? this.item.ifLeadTag_id : null,
      thenBranch_id: this.item.thenBranch_id ? this.item.thenBranch_id : null,
      preloader: false,
      valid: true,
      reqRules: [(v) => !!v || "Pflichtfeld"],
    };
  },

  // watch for changes in goalReached
  watch: {
    goalReached: function (val) {
      if (val) {
        this.thenBranch_id = null;
      }
    },
  },

  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        // Formular ist gültig, führen Sie die gewünschte Aktion aus

        const params = new URLSearchParams();
        params.append("ifLeadTag_id", this.ifLeadTag_id);
        params.append("thenBranch_id", this.thenBranch_id);

        // PATCH campaignFunnel/{funnelId}/branches/{branchId}/sets/{id}
        this.patchRoute = `campaignFunnel/${this.item.funnel_id}/branches/${this.item.branch_id}/conditions/${this.item.id}`;
        this.preloader = true;
        let response = await this.patchRequest(this.patchRoute, params);
        this.preloader = false;

        if (response.status === 200) {
          this.valueChanged = false;
          this.$emit("changed", false);
          this.$emit("patched", response.data.data);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Der Datensatz wurde geupdated!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
